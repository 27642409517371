import React, {useEffect, useState} from 'react';
import styles from './RightCard.module.scss';
import { observer } from 'mobx-react-lite';
import {Box, ListItem} from '@mui/material';
import CheckEmpty from '@helpers/components/CheckEmpty/CheckEmpty';
import {getDefEvent} from '@helpers/defaultsData/defaultsData';
import {ResultEventData} from '@store/eventState/interface';
import eventState from '@store/eventState/eventState';
import dashboardState from '@store/dashboardState/dashboardState';
import { cardsRightCard } from '@components/Dashboard/data';

const RightCard: React.FC = observer(() => {
	const { isSelectEvent, selectedTableId, selectedRowId } = dashboardState;
	const { eventsDividedByType } = eventState;

	const [event, setEvent] = useState<ResultEventData>(getDefEvent());

	const selectedTable = eventsDividedByType[selectedTableId];
	const cardName = isSelectEvent && cardsRightCard[selectedTableId];
	const imgLink = event.img;
	const isSound = !!event?.sound?.track?.length && event?.sound?.energy !== 0;

	const infoTable = [
		{ id: 1, name: 'id:', value: event.id},
		{ id: 2, name: 'Устройство:', value: event.cameraName },
		{ id: 3, name: 'Позиция камеры:', value: event.presetName },
		{ id: 4, name: 'Зона:', value: event.zoneName },
		{ id: 5, name: 'Дата:', value: event.date },
		{ id: 6, name: 'Время:', value: event.time },
		{ id: 7, name: 'Минимальная температура:', value: event.viewData.airTemp.value.toFixed(1), symbol: '°C' },
		{ id: 8, name: 'Средняя температура:', value: event.viewData.temperGraph.value.toFixed(1), symbol: '°C' },
	];

	const infoSound = [
		{ id: 1, name: 'Звуковая энергия:', value: event.soundEnergy, symbol: '%' },
		// { id: 2, name: 'Звуковое сравнение:', value: event.soundCorrelation, symbol: '%' },
		{ id: 3, name: 'Частота звука:', value: event.soundFrequency, symbol: 'Гц' },
	];

	useEffect(() => {
		selectedTable?.map(row => {
			const isCorrectRow = row.id && +row.id === selectedRowId;

			if (isCorrectRow) setEvent(row);
		});
	}, [selectedTable, selectedRowId]);

	return (
		<div className={styles.rightCard}>
			<CheckEmpty isCheck={isSelectEvent}>
				{cardName}

				{infoTable.map(({ id, name, value, symbol }) => (
					<ListItem key={`${id}-${name}`} className={styles.tableRow} sx={{ justifyContent: 'space-between' }}>
						<div>{name}</div>

						<div style={{display: 'flex'}}>
							<Box style={{marginRight: '5px', maxWidth: 170}}>{value}</Box>
							<Box>{symbol}</Box>
						</div>
					</ListItem>
				))}

				<img className={styles.image} src={imgLink} alt='Photo' />

				{isSound && infoSound.map(({ id, name, value, symbol }) => (
					<ListItem key={`${id}-${name}`} className={styles.tableRow} sx={{ justifyContent: 'space-between' }}>
						<Box>{name}</Box>

						<div style={{display: 'flex'}}>
							<Box style={{marginRight: '5px'}}>{value}</Box>
							<Box>{symbol}</Box>
						</div>
					</ListItem>
				))}

				<ListItem>
					{isSound ? (
						<audio src={event.sound.track} controls/>
					) : (
						<Box>{'Нет звукозаписей'}</Box>
					)}
				</ListItem>
			</CheckEmpty>
		</div>
	);
});

export default RightCard;
