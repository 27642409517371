import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import styles from './Account.module.scss';
import PersonCard from './PersonCard/PersonCard';
import CustomCard from './CustomCard/CustomCard';
import {
	AccountActionsTable,
	AccountModalChange,
	AccountModalContent as ModalContent,
	AccountProps as Props,
	AccountSelectedItem,
	AccountTypeForModal,
} from './Account.interface';
import { observer } from 'mobx-react-lite';
import devicesState from '@store/devicesState/devicesState';
import {DeviceAccount} from '@store/devicesState/interface';
import {AccountPlace} from '@store/placeState/interfaces';
import ContentModal from './ContentModal/ContentModal';
import Modal from '@mui/material/Modal';
import CustomTable from './CustomTable/CustomTable';
import {
	CustomCardItemCameraProps,
	CustomCardItemPlaceProps,
} from './CustomCard/CustomCard.interface';
import { CustomTableItemData } from './CustomTable/CustomTable.interface';
import connectState from '@store/connectState/connectState';
import autorizeState from '@store/accoutState/autorizeState';
import userActionsState from '@store/userActionsState/userActionsState';
import PersonCardHeader from './PersonCard/PersonCardHeader/PersonCardHeader';
import { SelectedUser, UserDataFix } from '@store/accoutState/interfaces';
import { apiGet } from '@api/api';
import {PrepareDevice, TechUser} from '@api/AccountPage/prepareAccountData.interface';
import { prepareDeviceData, prepareSelectedUser } from '@api/AccountPage/prepareUserData';
import {TabsComponent } from './ContentModal/ContentForManager/Tabs/Tabs';
import CameraTable from './CameraTable/CameraTable';

const Account: React.FC<Props> = observer(({actionsTable}) => {
	const { accountDevices } = devicesState;
	const { user, users, updateData, accountPlaces, techUsers } = autorizeState;
	const { usersActions } = userActionsState;
	const { getPlacesLink, getCameraInfo, getPlaceInfo, changeUserLink } = connectState;

	const role = user.role;
	const groupRole = user.grouprole;

	const [open, setOpen] = useState(false);
	const [placesData, setPlacesData] = useState<AccountPlace[]>([]);
	const [camerasData, setCamerasData] = useState<DeviceAccount[]>([]);
	const [usersData, setUsersData] = useState<UserDataFix[]>([]);
	const [typeForModal, setTypeForModal] = useState<keyof AccountTypeForModal>('camera');
	const [typeModalByAction, setTypeModalByAction] = useState<keyof ModalContent>('create');
	const [selectedId, setSelectedId] = useState<number | string>('');
	const [selectedItem, setSelectedItem] = useState<object>({});
	const [selectedSpot, setSelectedSpot] = useState<AccountPlace>(accountPlaces[0]);
	const [selecedTechUser, setSelectedTechUser] = useState<TechUser>(techUsers[0]);
	const [isChangeUserRole, setChangeUserRole] = useState(false);
	const [isProfile, setProfile] = useState(false);

	useEffect(() => {
		setPlacesData(accountPlaces);
	}, [accountPlaces, updateData.isPlaces]);

	useEffect(() => {
		setCamerasData(accountDevices);
	}, [accountDevices, updateData.isDevices]);

	useEffect(() => {
		setUsersData(users);
	}, [users]);

	const handleShowModalAdd = (type: keyof AccountModalChange) => {
		setTypeForModal(type as keyof AccountTypeForModal);
		setTypeModalByAction('create');
		setOpen(true);
	};

	const handleShowModalChange = (type: keyof AccountSelectedItem, id: number | string, isCanEdit: boolean) => {
		setTypeForModal(type);
		setSelectedId(id);
		setChangeUserRole(false);

		const findAction = findSelectedItem[type];
		findAction(id, isCanEdit);

		setTypeModalByAction('change');
	};

	const handleChangeUserInf = (isProfile: boolean) => {
		setSelectedId(user.id);
		setSelectedItem(user);
		setTypeForModal('manage');
		setChangeUserRole(true);

		setTypeModalByAction('change');
		setOpen(true);
		setProfile(isProfile);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedItem({});
	};

	const findSelectedCamera = (selectId: string | number, isCanEdit: boolean) => {
		const url = `${getCameraInfo}${selectId}/getshortinfo/`;
		const controller = new AbortController();

		apiGet(url, {}, controller).then(({ data, error }) => {
			const { isError } = error;
			if (isError) return;

			const device = prepareDeviceData(data as PrepareDevice);
			setSelectedItem({...device, isCanEdit: isCanEdit});

			setSelectedSpot(device.place);
			let selectedTechIndex = techUsers.findIndex(techUser => techUser.id === device.cameraUser);
			if (selectedTechIndex === -1) selectedTechIndex = 0;
			setSelectedTechUser(techUsers[selectedTechIndex]);
			setOpen(true);
		});
	};

	const findSelectedPlace = (selectId: string | number, isCanEdit: boolean) => {
		const url = `${getPlaceInfo}${selectId}/getinfo/`;
		const controller = new AbortController();

		apiGet(url, {}, controller).then(({ data, error }) => {
			const { isError } = error;
			if (isError) return;

			setSelectedItem({...data, isCanEdit: isCanEdit});
			setOpen(true);
		});

		return () => {
			controller.abort();
		};
	};

	const findSelectedManage = (selectId: string | number) => {
		const controller = new AbortController();
		const url = `${changeUserLink}${selectId}/`;

		apiGet(url, {}, controller).then(({ data, error }) => {
			const { isError } = error;

			if (isError) return;
			const selectedUser = prepareSelectedUser(data as SelectedUser);

			setSelectedItem(selectedUser);
			setOpen(true);
		});
	};

	const findSelectedAction = () => {
		setSelectedItem(usersActions[0]);
	};

	const findSelectedItem: AccountSelectedItem = {
		camera: (selectId: string | number, isCanEdit: boolean) => findSelectedCamera(selectId, isCanEdit),
		location: (selectId: string | number, isCanEdit: boolean) => findSelectedPlace(selectId, isCanEdit),
		manage: (selectId: string | number) => findSelectedManage(selectId),
		action: () => findSelectedAction(),
	};

	const modalContent: ModalContent = {
		create: {
			action: <ContentModal title={typeForModal} handleClose={handleClose} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
			camera: <ContentModal title={typeForModal} handleClose={handleClose} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
			manage: <TabsComponent title={'Создать'} handleClose={handleClose} selectedItem={selectedItem} type={typeModalByAction}/>,
			location: <ContentModal title={typeForModal} handleClose={handleClose} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
		},
		change: {
			action: <ContentModal title={typeForModal} handleClose={handleClose} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
			camera: <ContentModal title={typeForModal} handleClose={handleClose} id={selectedId} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
			manage: <TabsComponent title={'Изменить'} handleClose={handleClose} selectedItem={selectedItem} type={typeModalByAction} isChangeUserRole={isChangeUserRole}/>,
			location: <ContentModal title={typeForModal} handleClose={handleClose} id={selectedId} typeModal={typeModalByAction} selectedItem={selectedItem} spot={selectedSpot} techUser={selecedTechUser}/>,
			user: <TabsComponent title={'Изменить'} handleClose={handleClose} selectedItem={user} type={'create'} isProfile={isProfile}/>,
		}
	};

	const customTableNumber: CustomCardItemPlaceProps[] = [
		{
			data: placesData,
			title: 'Локации',
			create: (type: keyof AccountTypeForModal) => handleShowModalAdd(type),
			change: (type: keyof AccountTypeForModal, id: number | string, isCanEdit: boolean) => handleShowModalChange(type, id, isCanEdit),
			type: 'location',
			url: getPlacesLink,
			updateTitle: 'isPlaces',
			isLowerTable: false
		},
		{
			data: camerasData,
			title: 'Камеры',
			create: (type: keyof AccountTypeForModal) => handleShowModalAdd(type),
			change: (type: keyof AccountTypeForModal, id: number | string, isCanEdit: boolean) => handleShowModalChange(type, id, isCanEdit),
			type: 'camera',
			updateTitle: 'isDevices',
			url: getCameraInfo,
			isLowerTable: false
		},
	];

	const actionManageTable:AccountActionsTable = {countRows: 10, nextLink: '', prevLink: '', handleChangePage: () => console.log()};
	const customLowerTable: CustomTableItemData[] = [
		{
			data: usersData,
			title: 'Управление доступом',
			create: (type: keyof AccountModalChange) => handleShowModalAdd(type),
			change: (type: keyof AccountSelectedItem, id: number | string) => handleShowModalChange(type, id, false),
			type: 'manage',
			url: '',
			actionsTable: actionManageTable
		},
		{
			data: usersActions,
			title: 'Журнал действий',
			create: (type: keyof AccountModalChange) => handleShowModalAdd(type),
			change: (type: keyof AccountSelectedItem, id: number | string) => handleShowModalChange(type, id, false),
			type: 'action',
			url: '',
			actionsTable: actionsTable
		},
	];

	const createNewCard = ({ title, data, create, type, isLowerTable, change, url, updateTitle }: CustomCardItemPlaceProps) => {
		const customCard = { title, data, create, type, isLowerTable, change, url, updateTitle };
		const key = `CustomCard-${title}-${type}-${isLowerTable}`;

		return <CustomCard key={key} item={customCard} role={role} groupRole={groupRole}/>;
	};

	const createCameraCard = ({ title, data, create, type, isLowerTable, change, url, updateTitle }: CustomCardItemCameraProps) => {
		const customCard = { title, data, create, type, isLowerTable, change, url, updateTitle };
		const key = `CameraTable-${title}-${type}-${isLowerTable}`;

		return <CameraTable key={key} item={customCard} role={role} groupRole={groupRole}/>;
	};

	const createNewTable = ({ title, data, create, change, type, url, actionsTable }: CustomTableItemData) => {
		const customCard = { title, data, create, change, type,  url, actionsTable };
		const key = `CustomCard-${title}-${type}`;

		return <CustomTable key={key} item={customCard} role={role} groupRole={groupRole}/>;
	};

	return (
		<Box className={styles.container}>
			<div className={styles.boxCardsTop}>
				<div className={styles.box}>
					<PersonCardHeader actionBtn={handleChangeUserInf} />
					<PersonCard />
				</div>

				{createNewCard(customTableNumber[0])}
				{createCameraCard(customTableNumber[1] as CustomCardItemCameraProps)}
			</div>

			{user.grouprole === 'groupadmin' &&
				(
					<div className={styles.boxCards}>
						{createNewTable(customLowerTable[0])}
						<CustomTable item={customLowerTable[1]} role={role} groupRole={groupRole}/>
					</div>
				)
			}

			<Modal open={open} onClose={handleClose}>
				<div>
					{modalContent[typeModalByAction][typeForModal]}
				</div>
			</Modal>
		</Box>
	);
});

export default Account;
