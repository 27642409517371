import styles from './ControlBottomIcons.module.scss';
import { ControlBottomIconsProps as Props } from './ControlBottomIcons.interface';
import MainButton from '@helpers/components/MainButton/MainButton';
import { DashboardIcon } from '@assets/Icons/LeftMenu';
import SensorPageIcon from '@assets/Icons/ControlPage/SensorPageIcon';

const ControlBottomIcons: React.FC<Props> = ({ isMainDashboard, setIsMainDashboard }) => {

	const menuIcons = [
		{ id: 1, Icon: DashboardIcon, fill: isMainDashboard ? '#00C2FD' : '#D2D2D2', visibleClick: () => setIsMainDashboard(true) },
		{ id: 2, Icon: SensorPageIcon, fill: isMainDashboard ? '#D2D2D2' : '#00C2FD', visibleClick: () => setIsMainDashboard(false) },
	];

	return (
		<div className={styles.container}>
			{menuIcons.map((icon) => {
				const { id, Icon, fill, visibleClick } = icon;
				const key = `Menu-Icon-${id}`;

				return (
					<div key={key} className={styles.bottomIcons} >
						<MainButton onClick={visibleClick}>
							<Icon fill={fill} title='Таблица'/>
						</MainButton>
					</div>
				);
			})}
		</div>
	);
};

export default ControlBottomIcons;
