import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styles from './AccountPage.module.scss';
import Account from '@components/Account/Account';
import { AccountPageProps } from '../Pages.interface';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import connectState from '@store/connectState/connectState';
import { apiGet, apiPost } from '@api/api';
import MainButton from '@helpers/components/MainButton/MainButton';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import { useNavigate } from 'react-router-dom';
import { checkPageReady } from '@helpers/functions/loading/accountLoading';
import { prepareTechUsers, prepareUserAccess, prepareUserDataFix, prepareUserDevices, prepareUsersData } from '@api/AccountPage/prepareUserData';
import { PrepareTechUser, PrepareUserAccess } from '@api/AccountPage/prepareAccountData.interface';
import autorizeState from '@store/accoutState/autorizeState';
import devicesState from '@store/devicesState/devicesState';
import {DataUserFix, Group, UsersAll} from '@store/accoutState/interfaces';
import { AccountPlace } from '@store/placeState/interfaces';
import { prepareUsersActionsData } from '@api/AccountPage/prepareUsersActionsData';
import userActionsState from '@store/userActionsState/userActionsState';
import { RowDataUsersActions } from '@store/userActionsState/interfaces';
import { PaginationProps } from '@components/Control/TableEvents/TableEvents.interface';
import {PrepareDeviceAccount} from '@store/devicesState/interface';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';

export const postUserAction = (userAction = '', type = '' ) => {
	const message = `${userAction} ${type}`;
	const postobj = { message };
	const { postUserAction } = connectState;
	const { setUpdateData } = autorizeState;

	apiPost(postUserAction, postobj, {}).then(({ error }) => {
		const { isError } = error;

		if (isError) return;
		setUpdateData('isUsersActions');
	});
};

export const getUserDevice = () => {
	const { checkAuth, setLoadingTables  } =  autorizeState;
	const { userCameras } = connectState;
	const { setAccountDevices } = devicesState;

	apiGet(userCameras, []).then(({ data, error, ans }) => {
		if (error.isError) {
			checkAuth(ans.status);
			return;
		}

		if (error.isError) return;
		setLoadingTables('isDevices', false);
		const cameras = prepareUserDevices(data as PrepareDeviceAccount[]);
		setAccountDevices(cameras);
	});
};

export const getUsers = () => {
	const { checkAuth, setLoadingTables, setUsersData  } =  autorizeState;
	const { getAllUsersLink } = connectState;
	apiGet(getAllUsersLink, []).then(({ data, error, ans }) => {
		if (error.isError) {
			checkAuth(ans.status);
			return;
		}

		if (error.isError) return;
		const users = prepareUsersData(data as UsersAll[]);
		setUsersData(users);
		setLoadingTables('isUsers', false);
	});
};

const AccountPage: React.FC<AccountPageProps> = observer(({ metaTags }) => {
	const navigate = useNavigate();

	const {
		userLink,
		getGroupLink,
		getUsersActionsLink,
		allUserAccess,
		userCameras,
		getAllUsersLink,
		getTechUsersLink ,
	} = connectState;
	const { setAccountDevices, setAddAccessDevices } = devicesState;
	const { addUsersActions } = userActionsState;

	const {
		setTechUsers,
		setUserData,
		updateData,
		setUsersData,
		setAllGroups,
		setAccountPlaces,
		setAddAccessPlaces,
		checkAuth,
		filterLinks,
		setUsersFilterLink,
		setCameraFilterLink,
		setLoadingTables,
		user
	} = autorizeState;

	const [countRows, setCountRows] = useState<number>(0);
	const [prevLink, setPrevLink] = useState('');
	const [nextLink, setNextLink] = useState('');
	const [pageCounter, setPageCounter] = useState<number>(0);
	const [userID, setUserID] = useState<number | undefined>(undefined);

	useEffect(() => {
		const controller = new AbortController();
		if (!userLink) return;
		getUserData(userLink, controller);

		return () => {
			controller.abort();
		};
	}, [userLink, updateData.isUser]);

	useEffect(() => {
		const controller = new AbortController();
		if (!getTechUsersLink) return;

		getTechUsers(getTechUsersLink, controller);

		return () => {
			controller.abort();
		};
	}, [getTechUsersLink]);

	useEffect(() => {
		if (!getAllUsersLink || user.grouprole !== 'groupadmin') return;

		setUsersFilterLink(getAllUsersLink);
	}, [getAllUsersLink,  user.grouprole]);

	useEffect(() => {
		if (!userCameras) return;

		setCameraFilterLink(userCameras);
	}, [userCameras]);

	useEffect(() => {
		const controller = new AbortController();
		if (!getUsersActionsLink || user.grouprole !== 'groupadmin') return;

		getUserActionData(getUsersActionsLink, controller);

		return () => {
			controller.abort();
		};
	}, [getUsersActionsLink, updateData.isUsersActions,  user.grouprole]);


	useEffect(() => {
		const controller = new AbortController();

		if (!userID || !allUserAccess ) return;

		const url = `${allUserAccess}${userID}/`;
		getUserAccess(url, controller);

		return () => {
			controller.abort();
		};
	}, [allUserAccess, userID, updateData.isGetAccess]);

	useEffect(() => {
		const controller = new AbortController();
		if (!getGroupLink) return;

		getGroup(getGroupLink, controller);

		return () => {
			controller.abort();
		};
	}, [updateData.isGroups]);

	useEffect(() => {
		const controller = new AbortController();

		if (!filterLinks.cameraLink || !userCameras) return;

		getUserDevice(filterLinks.cameraLink, controller);

		return () => {
			controller.abort();
		};
	}, [filterLinks.cameraLink]);

	useEffect(() => {
		const controller = new AbortController();
		if (!filterLinks.userLink || !getAllUsersLink) return;

		getUsers(filterLinks.userLink, controller);

		return () => {
			controller.abort();
		};
	}, [filterLinks.userLink]);


	const getUserData = (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			if (error.isError) return;

			const userData = prepareUserDataFix(data as DataUserFix);
			const profile = userData.profile;
			setUserData(profile);
			setUserID(profile.id);

			setAccountPlaces(userData.spots as AccountPlace[]);
			getGroup(getGroupLink, controller);


			checkPageReady('isUser', true);
		});
	};

	const getUserDevice = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			if (error.isError) return;
			setLoadingTables('isDevices', false);
			const cameras = prepareUserDevices(data as PrepareDeviceAccount[]);
			setAccountDevices(cameras);
		});
	};

	const getTechUsers = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			if (error.isError) return;
			const techUsers = prepareTechUsers(data as PrepareTechUser[]);
			setTechUsers(techUsers);
		});
	};

	const getUsers = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}

			if (error.isError) return;
			const users = prepareUsersData(data as UsersAll[]);
			setUsersData(users);
			setLoadingTables('isUsers', false);
		});
	};


	const getUserAccess = (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;

			if (isError) return;
			const userAccess = prepareUserAccess(data as PrepareUserAccess[]);
			setAddAccessPlaces(userAccess);
			setAddAccessDevices(userAccess);
		});
	};

	const updateEvents = (link: string) => {
		const controller = new AbortController();

		getUserActionData(link, controller);

		return () => {
			controller.abort();
		};
	};

	const getGroup = (url = '', controller = new AbortController()) => {
		const { user } = autorizeState;
		apiGet(url, {}, controller).then(({ data, error }) => {
			if (error.isError) return;
			const allGroups = user.role === 'staff' ? data as Group[] : [data] as Group[];
			setAllGroups(allGroups);
		});
	};

	const getUserActionData = (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error }) => {

			if (error.isError) return;
			const actionsData = prepareUsersActionsData(data as RowDataUsersActions);

			const { actions, pagination } = actionsData;
			addUsersActions(actions);
			setCountRows(pagination.count);
			setPrevLink(pagination.previous);

			setNextLink(pagination.next);
			setLoadingTables('isUsersActions', false);
		});
	};

	const handleChangePage = (infoPage: PaginationProps) => {
		let linkMove = pageCounter < infoPage.page ? nextLink : prevLink;

		if (!linkMove) {
			linkMove = getUsersActionsLink;
		}

		const urlParts = linkMove.split('&');
		let updatelink = linkMove;

		if (urlParts.length > 1) {
			const queryParams = new URLSearchParams(urlParts[1]);

			urlParts.forEach((item, index) => {
				if (index < 2) return;

				const [key, value] = item.split('=');
				queryParams.set(key, value);
			});

			queryParams.set('page_size', infoPage.pageSize.toString());
			updatelink = `${urlParts[0]}&${queryParams.toString()}`;
		} else {
			updatelink = `${linkMove}&page_size=${infoPage.pageSize}`;
		}
		updateEvents(updatelink);

		setPageCounter(infoPage.page);
	};

	const handleGoBack = () => {
		navigate(-1);
	};

	const actionsTable = {
		countRows: countRows,
		prevLink: prevLink,
		nextLink: nextLink,
		handleChangePage: handleChangePage
	};

	return (
		<CheckLoading page='account'>
			<div className={styles.main}>
				<HelmetTags metaTags={metaTags} />
				<MainButton className={styles.buttonPos} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>
				<Account actionsTable={actionsTable} />
			</div>
		</CheckLoading>
	);
});

export default AccountPage;
