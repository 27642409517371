import React, {useState} from 'react';
import styles from './SensorsDashboard.module.scss';
import TempIcon from '@assets/Icons/ControlPage/TempIcon';
import ValuesIcon from '@assets/Icons/ControlPage/ValuesIcon';
import SensorsButtons from '../../Control/Sensors/SensorsButtons/SensorsButtons';
import VoltmeterIcon from '@assets/Icons/ControlPage/VoltmeterIcon';
import AmpermeterIcon from '@assets/Icons/ControlPage/AmpermeterIcon';
import eventState from '@store/eventState/eventState';
import { SensorsTypeCards } from '../../Control/Sensors/Sensors.interface';
import Modal from '@mui/material/Modal';
import CloseIcon from '@assets/Icons/Map/CloseIcon';

const SensorsDashboard: React.FC = () => {
	const { eventsDividedByType } = eventState;

	const [isModal, setModal] = useState(true);

	const event = eventsDividedByType[0][0];

	const dataDef = [
		{ id: 0, spot: 'КП1', currentValue: 14, maxValue: 18, minValue: -2 },
		{ id: 1, spot: 'КП2', currentValue: 10, maxValue: 20, minValue: 0 },
		{ id: 2, spot: 'КП3', currentValue: 8, maxValue: 15, minValue: -5 },
		{ id: 3, spot: 'КП4', currentValue: 12, maxValue: 16, minValue: -3 },
		{ id: 4, spot: 'КП5', currentValue: 16, maxValue: 22, minValue: -1 },
		{ id: 5, spot: 'КП6', currentValue: 18, maxValue: 25, minValue: -4 },
		{ id: 6, spot: 'КП7', currentValue: 20, maxValue: 30, minValue: -6 },
		{ id: 7, spot: 'КП8', currentValue: 22, maxValue: 35, minValue: -3 },
		{ id: 8, spot: 'КП9', currentValue: 25, maxValue: 40, minValue: -5 },

	];

	const dataDef2 = [
		{id: 10, spot: 'КП1', discreteValue: 'Замкнут'},
		{id: 20, spot: 'КП1', discreteValue: 'Разомкнут'}
	];

	const tempArr = [dataDef[0], dataDef[1], dataDef[2], dataDef[3], dataDef[4],dataDef[5],dataDef[6],dataDef[7],dataDef[8]];
	const amperArr = [dataDef[4], dataDef[6], dataDef[7], dataDef[8]];
	const voltArr = [dataDef[6], dataDef[7], dataDef[8]];
	const discretepArr = [dataDef2[0],  dataDef2[1]];
	let id = 100;

	const typeCards: SensorsTypeCards[] = [
		{
			id: ++id,
			icon: <VoltmeterIcon width={100} height={100}/>,
			data:  voltArr,
			isDiscrete: false,
			type: 'Напряжение',
			valueType: 'В'
		},
		{
			id: ++id,
			icon: <TempIcon width={90} height={90} viewBox={'22 18 40 40'}/>,
			data: tempArr,
			isDiscrete: false,
			type: 'Температура',
			valueType: '°C'
		},
		{
			id: ++id,
			icon: <AmpermeterIcon  width={100} height={100}/>,
			data: amperArr,
			isDiscrete: false,
			type: 'Сила тока',
			valueType: 'А'
		},
		{
			id: ++id,
			icon: <ValuesIcon width={100} height={100}/>,
			data: discretepArr,
			isDiscrete: true,
			type: 'Дискретные значения',
			valueType: ''
		}
	];

	return (
		<div className={styles.content} >
			{typeCards.map((item, index) => (
				<SensorsButtons item={item} event={event} key={`Modal-Sensor-Item-${item.id}-${index}-Type-${item.type}`}/>
			))}

			<Modal
				className={styles.modal}
				open={isModal}
				onClose={() => setModal(false)}
			>
				<div className={styles.modalContainer}>
					<div className={styles.closeIcon} onClick={() => setModal(false)}>
						<CloseIcon/>
					</div>

					Демонстрационный режим!
				</div>
			</Modal>
		</div>
	);
};

export default SensorsDashboard;
