import React, { useState } from 'react';
import styles from './ContactUs.module.scss';
import IconsBox from './IconsBox/IconsBox';
import { ClickAwayListener } from '@mui/material';

const ContactUs: React.FC = () => {
	const [isOpen, setIsOpen] = useState(false);

	const currentYear = new Date().getFullYear();

	const handleOpen = () => {
		setIsOpen(true);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<div className={styles.contactUs}>
			<div className={styles.btnRight}></div>
			<div className={styles.btnCenter} onClick={handleOpen}>
				СПРАВКА И ПОДДЕРЖКА
			</div>
			<div className={styles.btnLeft}>
				ООО СЕРВИС СОФТ 2004 - {currentYear}
			</div>
			{isOpen &&
				(
					<ClickAwayListener onClickAway={handleClose}>
						<div className={styles.iconContainer}>
							<IconsBox/>
						</div>
					</ClickAwayListener>
				)
			}
		</div>
	);
};

export default ContactUs;
