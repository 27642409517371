import { RowDataUsersActions } from '@store/userActionsState/interfaces';

export const prepareUsersActionsData = (data: RowDataUsersActions) => {
	const {count, next, previous, results} = data;

	const pagination = { next, previous, count };

	const page = next
		? parseInt(next.split('page=').pop() || '0') - 2
		: previous
			? parseInt(previous.split('page=').pop() || '0') + 2
			: 1;

	const actions = results ? results.map((item, index) => {
		const {datetime, message, user, id, ip, full_name} = item;

		const dateObj = new Date(datetime);

		const time = dateObj.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'});
		const day = dateObj.toLocaleDateString('ru-RU', {day: '2-digit', month: '2-digit', year: 'numeric'});
		const counter = 10 * page + (index + 1);

		const newDate = `Дата ${day} Время ${time}`;

		const date = newDate;
		const firstName = full_name;

		return {
			date,
			message,
			userId: user,
			id,
			firstName,
			ip,
			counter,
		};
	}) : [];

	return { pagination, actions };
};

