import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styles from './DashboardPage.module.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardPageProps } from '../Pages.interface';
import { apiGet } from '@api/api';
import { prepareMapDevices, prepareMapPlaces } from '@api/MapPage/prepareMapData';
import { PrepareDevices, DataPlace } from '@api/MapPage/prepareMapData.interface';
import {prepareEvent} from '@api/Control/prepareControlData';
import {RawEvent} from '@api/Control/prepareControlData.interface';
import HelmetTags from '@helpers/components/HelmetTags/HelmetTags';
import MainButton from '@helpers/components/MainButton/MainButton';
import { checkPageReady } from '@helpers/functions/loading/dashboardLoading';
import CardSet from '@components/Dashboard/CardSet';
import { CheckLoading } from '@components/CheckLoading/CheckLoading';
import FieldSearchPlaces from '@components/FieldSearchPlaces/FieldSearchPlaces';
import SensorsDashboard from '@components/Dashboard/SensorsDashboard/SensorsDashboard';
import ControlBottomIcons from '@components/Dashboard/ControlBottomIcons/ControlBottomIcons';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import connectState from '@store/connectState/connectState';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import dashboardState from '@store/dashboardState/dashboardState';
import autorizeState from '@store/accoutState/autorizeState';
import eventState from '@store/eventState/eventState';

interface numberType {
	[keys: string]: number;
}

const DashboardPage: React.FC<DashboardPageProps> = observer(({ metaTags }) => {
	const { getPlacesLink, getDevicesLink } = connectState;
	const { selectPlaceId, setPlaces } = placeState;
	const { setDevices, selectDeviceId } = devicesState;
	const { setOneEvent } = eventState;
	const { setOpenFullWin } = dashboardState;
	const { checkAuth } = autorizeState;

	const navigate = useNavigate();

	const [isReconnect, setIsReconnect] = useState(false);
	const [isMainDashboard, setIsMainDashboard] = useState(true);

	const [isFirstConnect, setFirstConnect] = useState(true);

	const numberCard: numberType = {
		vd: 0,
		ir: 1,
		uv: 2,
		sound: 3,
	};

	useEffect(() => {
		if (!getPlacesLink) return;

		const controller = new AbortController();

		getPlaces(getPlacesLink, controller);

		return () => {
			controller.abort();
		};
	}, [getPlacesLink]);

	useEffect(() => {
		const controller = new AbortController();

		if (!getDevicesLink || !selectPlaceId) return;

		const url = `${getDevicesLink}${selectPlaceId}/getcameras/`;
		getDevices(url, controller);

		return () => controller.abort();
	}, [selectPlaceId, getDevicesLink]);

	useEffect(() => {
		let socket: WebSocket | string = '';
		const intervalId = setInterval(() => {
			try {
				const { wsLink } = connectState;
				const { token } = autorizeState;

				if (!selectDeviceId) return;
				socket = new WebSocket(`${wsLink}${selectDeviceId}/?token=${token}&page=dashboard`);

				socket.onopen = () => socketOpen(socket as WebSocket);
				socket.onmessage = (e) => socketMessage(e);
				socket.onerror = (e) => socketError(e);
				socket.onclose = (e) => socketClose(e);

				clearInterval(intervalId);
			} catch (error) {
				console.error(error);
			}
		}, 2000);

		return () => {
			try {
				if (socket instanceof WebSocket) socket.close();
			} catch (error) {
				console.error(error);
			}
			clearInterval(intervalId);
		};
	}, [isReconnect]);

	const socketOpen = (socket: WebSocket) => {
		if (isFirstConnect) return;
		toast.success('Соединение установлено.', {
			autoClose: 2000,
			className: styles.toastMessage
		});

		console.log(socket);
	};
	const socketMessage = (event: MessageEvent) => {
		console.log(event);

		try {
			const { data: eventSocket } = JSON.parse(event.data);
			const eventsData = prepareEvent(eventSocket as RawEvent);

			setOneEvent(eventsData, numberCard[eventsData.messageType]);
		} catch (error) {
			console.log('Не получилось прочитать ответ');
			console.error(error);
		}
	};
	const socketError = (error: Event) => {
		console.error(error);
	};

	const socketClose = (event: CloseEvent) => {
		const closeConnectMsg = 'Соединение закрыто чисто';
		const breakConnectMsg = 'Обрыв соединения';

		if (event.wasClean) console.log(closeConnectMsg);
		else {
			toast.error(`${breakConnectMsg}, Повторное соединение...`, {
				autoClose: 2000,
				className: styles.toastError,
			});
			setFirstConnect(false);
			setIsReconnect((prevIsBreak) => !prevIsBreak);
		}
	};

	const getPlaces = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const places = prepareMapPlaces(data as DataPlace[]);
			setPlaces(places);
			checkPageReady('isPlaces', true);
		});
	};

	const getDevices = async (url = '', controller = new AbortController()) => {
		apiGet(url, {}, controller).then(({ data, error, ans }) => {
			if (error.isError) {
				checkAuth(ans.status);
				return;
			}
			const devices = prepareMapDevices(data as PrepareDevices);
			setDevices(devices);
			checkPageReady('isDevices', true);
		});
	};

	const handleGoBack = () => {
		navigate(-1);
		setOpenFullWin(false);
	};

	return (
		<CheckLoading page='dashboard'>
			<ToastContainer />

			<div className={styles.fullScreen}>
				<HelmetTags metaTags={metaTags} />

				<MainButton className={styles.buttonPos} onClick={handleGoBack}>
					<BackIcon />
				</MainButton>

				<FieldSearchPlaces isDashboardPage={true}/>

				{isMainDashboard
					? <CardSet />
					: <SensorsDashboard />
				}

				<ControlBottomIcons isMainDashboard={isMainDashboard} setIsMainDashboard={setIsMainDashboard}/>
			</div>
		</CheckLoading>
	);
});

export default DashboardPage;
