import React, {useState} from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import pdf from '@assets/pdf/userManual.pdf';
import pdfDevice from '@assets/pdf/deviceManual.pdf';
import styles from './PdfFile.module.scss';
import {PdfFileProps} from './PdfFile.interface';
import PdfButtons from './PdfButtons/PdfButtons';
import CloseIcon from '@assets/Icons/Map/CloseIcon';
import MainButton from '@helpers/components/MainButton/MainButton';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfFile: React.FC<PdfFileProps> = ({handleShowPdf}) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pdfFileIndex, setPdfFileIndex] = useState(0);

	const height = window.innerHeight;

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
	};
	const handleChangePage = (n: number | null = null) => {
		setCurrentPage((prev) => prev + (n || 0));
	};

	const pdfFiles = [pdf, pdfDevice];

	const handleChangePdf = (index: number) => {
		setPdfFileIndex(index);
		setCurrentPage(1);
	};

	const handleDownloadPdf = () => {
		const link = document.createElement('a');
		link.href = pdfFiles[pdfFileIndex];
		link.download = 'manual.pdf';
		link.click();
	};

	return (
		<div className={styles.page}>
			<MainButton className={styles.closeIcon} onClick={() => handleShowPdf()}>
				<CloseIcon />
			</MainButton>

			<Document file={pdfFiles[pdfFileIndex]} onLoadSuccess={onDocumentLoadSuccess}>
				<Page pageNumber={currentPage} height={height - 90} inputProps={{ preventDefault: true }} />
			</Document>

			{!!numPages &&
				<div className={styles.buttonsContainer}>
					<PdfButtons
						handleChangePdf={handleChangePdf}
						currentPage={currentPage}
						handleChangePage={handleChangePage}
						lastPage={numPages || 0}
						index={pdfFileIndex}
						handleDownloadPdf={handleDownloadPdf}
					/>
				</div>
			}
		</div>
	);
};

export default PdfFile;
