import { Device } from '@store/devicesState/interface';
import { PlaceDevices as Place } from '@store/placeState/interfaces';
import { LatLngTuple } from 'leaflet';
import placeState from '@store/placeState/placeState';
import devicesState from '@store/devicesState/devicesState';
import autorizeState from '@store/accoutState/autorizeState';
import { DataPlace, PrepareDevices } from './prepareMapData.interface';

export const prepareMapPlaces = (data: DataPlace[]): Place[] => {
	const { selectPlaceId, setSelectPlaceId } = placeState;

	const oldSelectedIndex = data.findIndex(({ id }) => id === selectPlaceId);
	const isOldSelectedPlace = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const places = data.map((place) => {
		const { id, lat, lng, name } = place;

		const coords: LatLngTuple = [lat, lng];

		return {
			id,
			coords,
			name,
			isSelected: false,
			isHaveEvents: false,
		};
	});

	if(!places.length) return [];

	places[isOldSelectedPlace].isSelected = true;
	setSelectPlaceId(places[isOldSelectedPlace].id);

	return places;
};

export const prepareMapDevices = (data: PrepareDevices): Device[] => {
	const { selectDeviceId, setselectDeviceId } = devicesState;
	const { cameras, role, spot } = data;

	const oldSelectedIndex = cameras.findIndex(({ id_psw }) => id_psw === selectDeviceId);
	const isOldSelectedPlace = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const devices = cameras.map((device) => {
		const { id_psw, /*is_loop_preset,*/ lat, lng, /*loop_value,*/ name, /*photo, presets, settings, spot,*/ time_created, /*zones*/ } = device;

		const coords: LatLngTuple = [lat, lng];

		return {
			id: id_psw,
			coords,
			name,
			spot,
			timeCreated: time_created,
			isSelected: false,
		};
	});

	if(!devices.length) return [];

	devices[isOldSelectedPlace].isSelected = true;
	setselectDeviceId(devices[isOldSelectedPlace].id);

	autorizeState.setUserRole(role);

	return devices;
};
