import {HeatAnalitica, IRAnalitica, UVAnalitica} from '@store/analiticaState/interface';
import {Preset} from '@store/presetState/interfaces';
import presetState from '@store/presetState/presetState';
import {HeatResults, IRResults, UVResults} from './interface';

export interface PreparePreset {
	id: number;
	name: string;
	preset_number: number;
}

export const prepareHeatEvents = (data: HeatResults) => {
	const { count, next, previous, results } = data;

	const pagination = { next, previous, count };

	const page = next
		? parseInt(next.split('page=').pop() || '0') - 2
		: previous
			? parseInt(previous.split('page=').pop() || '0') + 2
			: 1;

	const analitica: HeatAnalitica[] = results.map((item, index) => {
		const { datetime_json, id, dt_created, id_psw, period_type, show_event, data } = item;
		const { date_begin, date_finish, date } = datetime_json;
		const counter = page + (index + 1);

		const {  image_base64, img_ir } = show_event;

		const prepareGrid = img_ir?.replace(/[\\[\]]/g, '').split('; ').map(value => value.split(', ').map((item) => Number((+item).toFixed(1))));
		// Транспонируем матрицу данных
		const temperGrid = prepareGrid[0]?.map((_, colIndex) => prepareGrid.map((row) => row[colIndex])).flat();

		const rows = 24;
		const columns = 32;
		const resultGrid = [];

		for (let i = 0; i < rows; i++) {
			const row = [];
			for (let j = 0; j < columns; j++) {
				const index = i * columns + j;
				row.push(temperGrid[index]);
			}
			resultGrid.push(row);
		}

		const newInfZones =  data?.map(({irPoints, max_t, min_t, id}) => {
			return {
				irPoints,
				maxT: +max_t.toFixed(1),
				minT: +min_t.toFixed(1),
				id,
				name: `Зона ${id}`
			};
		});

		return {
			id,
			dateBegin: getDate(date_begin),
			dateFinish: getDate(date_finish),
			date: getDate(date),
			dtCreated: getDateTime(dt_created),
			idPsw: id_psw,
			periodType: period_type,
			temperGrid: resultGrid,
			img: image_base64,
			zones: newInfZones,
			counter
		};
	});
	return {analitica, pagination};
};

export const prepareIREvents = (data: IRResults) => {
	const { count, next, previous, results } = data;
	const pagination = { next, previous, count };

	const page = next
		? parseInt(next.split('page=').pop() || '0') - 2
		: previous
			? parseInt(previous.split('page=').pop() || '0') + 2
			: 1;

	const analitica: IRAnalitica[] = results.map((item, index) => {
		const { datetime_json, id, dt_created, id_psw, period_type, show_event, data } = item;
		const { date_begin, date_finish, date } = datetime_json;
		const counter = page + (index + 1);

		const { image_base64, img_ir } = show_event;

		const prepareGrid = img_ir.replace(/[\\[\]]/g, '').split('; ').map(value => value.split(', ').map((item) => Number((+item).toFixed(1))));
		// Транспонируем матрицу данных
		const temperGrid = prepareGrid[0].map((_, colIndex) => prepareGrid.map((row) => row[colIndex])).flat();

		const rows = 32;
		const columns = 24;
		const resultGrid = [];

		for (let i = 0; i < rows; i++) {
			const row = [];
			for (let j = 0; j < columns; j++) {
				const index = i * columns + j;
				row.push(temperGrid[index]);
			}
			resultGrid.push(row);
		}

		const newInfZones =  data?.map(({ id_zone, name, zone_info }) => {

			return {
				id: id_zone,
				avarageT: +zone_info.average_t.toFixed(1),
				maxT: +zone_info.max_t.toFixed(1),
				minT: +zone_info.min_t.toFixed(1),
				idEventMax: zone_info.id_event_max,
				idEventMin: zone_info.id_event_min,
				name,
				irPoints: []
			};
		});

		return {
			id,
			dateBegin: getDate(date_begin),
			dateFinish: getDate(date_finish),
			date: getDate(date),
			dtCreated: getDateTime(dt_created),
			idPsw: id_psw,
			periodType: period_type,
			temperGrid: resultGrid,
			img: image_base64,
			zones: newInfZones.length === 0 ? [] : newInfZones,
			counter
		};
	});
	return {analitica, pagination};
};

export const prepareUVEvents = (data: UVResults) => {
	const { count, next, previous, results } = data;
	const pagination = { next, previous, count };

	const page = next
		? parseInt(next.split('page=').pop() || '0') - 2
		: previous
			? parseInt(previous.split('page=').pop() || '0') + 2
			: 1;

	const analitica: UVAnalitica[] = results.map((item, index) => {
		const { datetime_json, id, dt_created, id_psw, period_type, data } = item;
		const { date_begin, date_finish, date } = datetime_json;
		const counter = page + (index + 1);

		const chartCorona =  data['Коронные разряды']?.map(({ count, state, Datetime }) => {
			return {
				count,
				state,
				dateTime: Datetime
			};
		});

		const chartNoise =  data['Внешние шумы']?.map(({ count, state, Datetime }) => {
			return {
				count,
				state,
				dateTime: Datetime
			};
		});

		const newInfZones = {
			chartCorona: chartCorona ? chartCorona : [],
			chartNoise: chartNoise ? chartNoise : [],
		};

		return {
			id,
			dateBegin: getDate(date_begin),
			dateFinish: getDate(date_finish),
			dtCreated: getDateTime(dt_created),
			date: date ? getDate(date) : '',
			idPsw: id_psw,
			periodType: period_type,
			chart: newInfZones,
			counter
		};
	});
	return {analitica, pagination};
};


export const preparePresetsData = (data: PreparePreset[]): Preset[] => {
	const { selectPresetId, setSelectPresetId } = presetState;
	const oldSelectedIndex = data.findIndex(({ id }) => id === selectPresetId);
	const isOldSelectedPreset = oldSelectedIndex !== -1 ? oldSelectedIndex : 0;

	const presets = data.map((preset) => {
		const {id, name, preset_number} = preset;
		return {
			id,
			name,
			number: preset_number,
			isActive: false,
			t_min: 0,
			t_max: 1,
		};
	});

	if (!presets.length) {
		setSelectPresetId(null);
		return [];
	}

	presets[isOldSelectedPreset].isActive = true;
	setSelectPresetId(presets[isOldSelectedPreset].id);

	return presets;
};


const getDateTime = (dateTimeString: string): string => {
	const dateTime = new Date(dateTimeString);
	const day = dateTime.getDate();
	const month = dateTime.getMonth() + 1;
	const year = dateTime.getFullYear();
	const hours = dateTime.getHours();
	const minutes = dateTime.getMinutes();

	const formattedDate = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

	return formattedDate;
};

const getDate = (datetime: string): string => {
	const dateObj = new Date(datetime);
	const day = dateObj.getDate();
	const month = dateObj.getMonth() + 1;
	const year = dateObj.getFullYear();

	const formattedDate = `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;

	return formattedDate;
};
