import { CheckEmptyProps } from './CheckEmpty.interface';
import styles from './CheckEmpty.module.scss';

const CheckEmpty: React.FC<CheckEmptyProps> = ({ isCheck, children, title = 'Данных нет' }) => {
	return (
		<>
			{isCheck ? (
				children
			) : (
				<div className={styles.zeroData}>
					<p>{title}</p>
				</div>
			)}
		</>
	);
};

export default CheckEmpty;
