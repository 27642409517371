import React, {useEffect, useMemo, useState} from 'react';
import styles from './CameraTable.module.scss';
import CustomHeader from '../CustomHeader/CustomHeader';
import { CustomCardProps as Props } from './CameraTable.interface';
import {
	DataGrid,
	getGridStringOperators,
	GridColDef,
	GridFilterModel,
	GridFilterOperator,
	GridRowParams
} from '@mui/x-data-grid';
import {ThemeProvider, createTheme, styled} from '@mui/material/styles';
import {DeviceAccount} from '@store/devicesState/interface';
import connectState from '@store/connectState/connectState';
import autorizeState from '@store/accoutState/autorizeState';
import {filterOperator} from '@helpers/defaultsData/defaultsData';
import CheckEmpty from '@helpers/components/CheckEmpty/CheckEmpty';

const filteredFields: { [key: string]: string } = {
	spotName: 'spot_name',
};

const ModDataGrid = styled(DataGrid)({
	mxHeight: 'calc(100% - 50px)',
	width: '100%',

	border: 'none',

	color: '#D2D2D2',

	'& .MuiTablePagination-root': {
		position: 'absolute',
		right: '0',
		color: '#D2D2D2',
	},

	'& .MuiDataGrid-selectedRowCount': {
		display: 'none',
	},

	'& .MuiDataGrid-columnHeaderTitleContainer': {
		fontSize: '18px',
	},

	'& .MuiDataGrid-cell': {
		fontSize: '16px'
	},

	'& .MuiTablePagination-selectLabel': {
		padding: '0',
		fontSize: '16px',
	},

	'& .MuiTablePagination-displayedRows': {
		padding: '0',
		fontSize: '16px',
	},

	'& ::-webkit-scrollbar': {
		width: '6px'
	},

	'& ::-webkit-scrollbar-thumb': {
		backgroundColor: '#ccc',
		borderRadius: '3px',
	}
});

const localizedTextsMap = {
	columnMenuUnsort: 'Отменить сортировку',
	columnMenuSortAsc: 'По возрастанию',
	columnMenuSortDesc: 'По убыванию',
	columnMenuFilter: 'Фильтр',
	columnMenuHideColumn: 'Скрыть',
	columnMenuManageColumns: 'Показать столбцы',

	columnsPanelTextFieldLabel: 'Поиск столбца',
	columnsPanelTextFieldPlaceholder: 'Название столбца',
	columnsPanelShowAllButton: 'Показать все',
	columnsPanelHideAllButton: 'Скрыть все',
};

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

const CameraTable: React.FC<Props> = ({ item, role, groupRole }) => {
	const { title, data, create, type, change } = item;
	const { userCameras } = connectState;
	const { setCameraFilterLink, setLoadingTables, loadingTables } = autorizeState;

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Имя ', flex: 1, filterable: false},
		{ field: 'spotName', headerName: 'Локация ', flex: 1},
	];
	const [isFilter, setFilter] = useState(false);
	const [filteredMod, setFilteredMod] = useState('');
	const [isLoad, setLoad] = useState(false);

	useEffect(() => {
		setLoad(loadingTables.isDevices);
	}, [loadingTables.isDevices]);

	useEffect(() => {
		if (!filteredMod) return;

		const urlParts = userCameras.split('?');
		let updatedLink = userCameras;

		if (urlParts.length > 1) {
			const searchParams = new URLSearchParams(urlParts[1]);

			const eventType = searchParams.get('first_name');
			const ordering = searchParams.get('ordering');
			const pageSize = searchParams.get('page_size');
			const pageNumber = searchParams.get('page');

			const newQueryParams = new URLSearchParams();

			newQueryParams.set('first_name', eventType ? eventType : '');
			newQueryParams.set('ordering', ordering ? ordering : '');
			newQueryParams.set('page_size', pageSize ? pageSize : '');
			newQueryParams.set('page', pageNumber ? pageNumber : '1');

			updatedLink = `${urlParts[0]}?${newQueryParams.toString()}&${filteredMod}`;
		} else {
			updatedLink = `${userCameras}?${filteredMod}`;
		}
		setCameraFilterLink(updatedLink);
	}, [filteredMod, isFilter]);

	const handleFilterModelChange = (model: GridFilterModel) => {
		setFilter(false);

		if (!model.items.length) return;
		setLoadingTables('isDevices', true);

		const { field, operator, value } = model.items[0];
		if (value === undefined || value === '') {
			setFilteredMod(userCameras);
			return;
		}

		setFilter(true);

		setFilteredMod(`${filteredFields[field]}${filterOperator[operator]}=${value}`);
	};

	const columnsWithFilter = useMemo(() => {

		const commonFilterOperators = getGridStringOperators().filter(
			(operator) =>
				operator.value === 'equals' || operator.value === 'contains'
		);

		return columns.map((col) => {
			let filterOperators: GridFilterOperator[] = [...commonFilterOperators];
			switch (col.field) {
			case 'id':
			case 'spotName':
				filterOperators = getGridStringOperators().filter(
					(operator) => operator.value === 'contains'
				);
				break;

			default:
				break;
			}

			return {
				...col,
				filterOperators,
			};
		});
	}, [columns]);


	const handleRowDoubleClick = (params: GridRowParams) => {
		const selectedIndex = data.findIndex(({id}) => id === params.id);
		if (selectedIndex === -1 || !data || data.length === 0 ) return;
		const row = data[selectedIndex] as DeviceAccount;

		change(type, row.idPsw, row.isCanEdit);
	};

	return (
		<ThemeProvider theme={darkTheme}>
			<div className={styles.box}>
				<CustomHeader title={title} type={type} create={create} role={role} groupRole={groupRole}/>

				<CheckEmpty isCheck={!!data.length}>
					<div className={styles.content}>
						<ModDataGrid
							rows={data}
							columns={columnsWithFilter}
							getRowId={(row) => row.id}
							rowCount={data.length}
							localeText={localizedTextsMap}
							loading={isLoad}
							initialState={{
								pagination: { paginationModel: { pageSize: 10 } },
							}}
							pageSizeOptions={[10, 25, 50]}
							onRowDoubleClick={handleRowDoubleClick}
							onFilterModelChange={handleFilterModelChange}
						/>
					</div>
				</CheckEmpty>
			</div>
		</ThemeProvider>
	);
};

export default CameraTable;
