import styles from './IRAnaliticaCard.module.scss';
import React, { useEffect,  useRef, useState } from 'react';
import { IRAnaliticaCardProps as Props } from './IRAnaliticaCard.interface';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AnaliticaKeyForType } from '@components/Analitica/Analitica.interface';
import BackIcon from '@assets/Icons/LeftMenu/BackIcon';
import { Box } from '@mui/material';
import CanvasGridTemp from '@components/CanvasGridTemp/CanvasGridTemp';
import useWindowDimensions from '@helpers/functions/useWindowDimensions';
import { HeatAnalitica, IRAnalitica, PeriodTypeKeys, ZonesHeat } from '@store/analiticaState/interface';
import canvasState from '@store/canvasState/canvasState';
import Polygons from '@components/Translation/Canvas/Polygons';
import { preparePolygonsData } from '@api/Translation/prepareTranslationData';
import { PolygonItem, PreparePolygon } from '@store/presetState/interfaces';
import devicesState from '@store/devicesState/devicesState';
import presetState from '@store/presetState/presetState';
import { observer } from 'mobx-react-lite';
import { apiGet } from '@api/api';
import connectState from '@store/connectState/connectState';
import CheckEmpty from '@helpers/components/CheckEmpty/CheckEmpty';
import { prepareControlEvents } from '@api/Control/prepareControlData';
import { RawDataEvents } from '@api/Control/prepareControlData.interface';

const CustomTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: '#858585',
			borderRadius: 10,
			height: 50,
		},
		'&:hover fieldset': {
			borderColor: '#00C2FD',
		},
		'&.Mui-focused fieldset': {
			borderColor: '#00C2FD',
		},
		color: '#cfcfcf',
		fontSize: '20px',
		padding: '2px 0 0 10px',
	},
});

const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});

interface Parameter {
	title: string;
	value: number | string | undefined;
}

export type PeriodTypeData = {
	[key in keyof PeriodTypeKeys]: Parameter[];
}
type ContentParameters  = {
	[key in keyof AnaliticaKeyForType]: Parameter[];
}

const IRAnaliticaCard: React.FC<Props> = observer(({ countRows = 0,url ,updateEvents, prevLink, nextLink, pageCounter = 0, type, zones, data, setPageCounter, setSelectedPeriod, selectePeriod,  }) => {
	const { linkPolygons, getEventsLink } = connectState;

	const { setCanvas } = canvasState;
	const { selectDeviceId } = devicesState;
	const { selectPresetId } = presetState;
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const [testData, setTestData] = useState(data as HeatAnalitica | IRAnalitica);
	const [selectedZone, setSelectedZone] = useState<ZonesHeat>(zones[0] as ZonesHeat);
	const [tempGrid, setTempGrid] = useState<number[][]>([]);
	const [imgBackground, setImgBackground] = useState('');
	const [polygons, setPolygons] = useState<PolygonItem[]>([]);
	const [dates, setDates] = useState<string>('');
	const [idEvent, setIDEvent] = useState<number | undefined>(selectedZone?.idEventMax);

	const imgContainerRef = useRef<HTMLDivElement>(null);
	const maxTemp = !tempGrid ? 0 : Math.max(...tempGrid.map((row) => Math.max(...row)));
	const minTemp = !tempGrid ? 0 : Math.min(...tempGrid.map((row) => Math.min(...row)));

	useEffect(() => {
		setCanvas(canvasRef.current);
		setTestData(data);
		const date = selectePeriod === 'd' ? data?.date :`${data?.dateBegin} - ${data?.dateFinish}`;

		if (countRows === 0) return;
		setDates(date);
		setSelectedZone(zones[0]);

		if (type === 'ir') return;
		setTempGrid(testData?.temperGrid);

		setImgBackground(testData?.img);
	}, [selectDeviceId, selectPresetId, polygons, selectePeriod, data, pageCounter]);

	useEffect(() => {
		setIDEvent(selectedZone?.idEventMax);
	}, [selectedZone, selectDeviceId]);

	useEffect(() => {
		if (type === 'temp' || !selectedZone?.id) return;


		const url = `${linkPolygons}${selectDeviceId}/getzoneinfo/${selectedZone?.id}/`;
		getPolygons(url);
	}, [selectDeviceId, selectPresetId, selectedZone, selectePeriod, data]);

	useEffect(() => {
		if (type === 'temp' || !idEvent) return;
		const controller = new AbortController();

		const url = `${getEventsLink}${selectDeviceId}/getevents/?id=${idEvent}`;
		getEvents(url, controller);
	}, [selectDeviceId, selectPresetId, selectedZone, selectePeriod, data, idEvent]);

	const getEvents = async (url = '', controller = new AbortController()) => {
		apiGet(url, [], controller).then(({ error, data = {} }) => {
			const { isError } = error;
			if (isError) return;

			const eventsData = prepareControlEvents(data as RawDataEvents);
			const { dataEvents } = eventsData;

			if (!dataEvents.length) {
				setTempGrid([]);
				setImgBackground('');
			} else {
				setTempGrid(dataEvents[0].viewData.temperGrid);
				setImgBackground(dataEvents[0].img);
			}
		});
	};

	useEffect(() => {
		if (type === 'temp') return;

		canvasState.setRawData(selectDeviceId, selectPresetId, polygons);
	}, [selectDeviceId, selectPresetId, selectedZone, polygons, selectePeriod]);

	const getPolygons = async (url = '',  controller = new AbortController()) => {
		return apiGet(url, [], controller).then(({ error, data }) => {
			const { isError } = error;

			if (isError) return;
			const test = [{...data, temp_max: 1, temp_min: 0}];

			const polygons = preparePolygonsData(test as PreparePolygon[]);

			setPolygons(polygons);
		});
	};

	const handleChange = (event: React.SyntheticEvent<Element, Event>, value: ZonesHeat | null) => {
		setSelectedZone(value ?? { id: 0,  minT: 0, maxT: 0, name: '', irPoints: [], avarageT: 0});
	};

	const parametersIR: Parameter[] = [
		{ title: 'Максимальная температура', value: selectedZone?.maxT },
		{ title: 'Средняя температура', value: selectedZone?.avarageT },
		{ title: 'Минимальная температура', value: selectedZone?.minT },
	];

	const parametersEnerge: Parameter[] = [
		{ title: 'Максимальная температура', value: selectedZone?.maxT },
		{ title: 'Минимальная температура', value: selectedZone?.minT },
	];

	const parametersPeriod = [
		{ title: 'Дата аналитики', value: testData?.dtCreated },
	];

	const parametersDate = [
		{ title: 'Дата аналитики', value: testData?.dtCreated },
	];

	const periodData: PeriodTypeData = {
		d: parametersDate,
		w: parametersPeriod,
		m: parametersPeriod
	};
	const [componentPeriod, setComponentPeriod] = useState(periodData['d']);

	useEffect(() => {
		setComponentPeriod(periodData[selectePeriod]);
	}, [selectePeriod, pageCounter]);

	const contentParameters: ContentParameters = {
		temp: parametersEnerge,
		ir: parametersIR,
		energe: [{title: '', value: 0}]
	};

	const handleChangePeriod = ( value: string ) => {
		setSelectedPeriod(value as keyof PeriodTypeKeys);
		setPageCounter(0);
	};

	const buttonsPeriod = [
		{id: 0, title: 'День', action: handleChangePeriod, value: 'd'},
		{id: 1, title: 'Неделя', action: handleChangePeriod, value: 'w'},
		{id: 2 ,title: 'Месяц', action: handleChangePeriod, value: 'm'},
	];

	const { width, height } = useWindowDimensions();

	const changeW = Number(((width / 3 - 151 + 50)* 0.55).toFixed(1));
	const changeH = Number((changeW  * 9 / 16).toFixed(1));

	useEffect(() => {
		if (type === 'temp') return;

		const timeout = setTimeout(() => {
			canvasState.setCanvasReSize(changeW  , changeH);

			if (!canvasState.canvas) return;
			new Polygons(canvasState.canvas);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [width, height, changeW, selectedZone, selectePeriod, selectPresetId, selectDeviceId, pageCounter, changeH, type]);


	const handleChangePage = (page: number) => {
		let linkMove = page === 1 ? nextLink : prevLink;

		if (!linkMove) {
			linkMove = url;
		}

		const urlParts = linkMove.split('&');
		let updatelink = linkMove;

		if (urlParts.length > 1) {
			const queryParams = new URLSearchParams(urlParts[1]);

			urlParts.forEach((item, index) => {
				if (index < 2) return;

				const [key, value] = item.split('=');
				queryParams.set(key, value);
			});

			updatelink = `${urlParts[0]}&${queryParams.toString()}`;
		} else {
			updatelink = linkMove;
		}
		if (!updatelink) return;

		updateEvents(updatelink);
		setPageCounter(pageCounter + page);
	};

	useEffect(() => {
		if (type === 'ir') return;
		const canvas = canvasRef.current;
		if (!canvas) return;


		const ctx = canvas.getContext('2d');

		if (!ctx) return;
		ctx.imageSmoothingEnabled = true;
		ctx.clearRect(0, 0, canvas.width, canvas.height);

		const { irPoints } = selectedZone;

		const zoneData = irPoints;

		const canvasWidth = 32;
		const canvasHeight = 24;

		const xValues = zoneData?.map(zone => (zone.x / canvasWidth) * changeW);
		const yValues = zoneData?.map(zone => (zone.y / canvasHeight) * changeH);
		if (!xValues || !yValues) return;

		const minX = Math.min(...xValues);
		const minY = Math.min(...yValues);
		const maxX = Math.max(...xValues);
		const maxY = Math.max(...yValues);

		const width = maxX - minX;
		const height = maxY - minY;

		const newWidth = width * 1.05;
		const newHeight = height;

		ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';

		ctx.beginPath();
		ctx.fillRect(minX, minY, newWidth, newHeight);
		ctx.stroke();
	}, [selectePeriod, selectedZone, pageCounter]);

	return (
		<ThemeProvider theme={darkTheme}>
			<div className={styles.box}>
				<div className={styles.containerButtons}>
					{buttonsPeriod.map(({title, action, id, value}) => {
						const isActive = value === selectePeriod;
						return (
							<div
								key={`Buttons-Period-${title}-${id}-${value}`}
								onClick={() => action(value)}
								className={`${styles.buttonPeriod} ${isActive && styles.active}`}
							>
								{title}
							</div>
						);
					})}
				</div>
				<CheckEmpty isCheck={!!zones?.length}>
					<div className={styles.contentbox}>
						<div
							className={styles.imageBox}
							ref={imgContainerRef}
							style={{
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
								backgroundImage: `url(${imgBackground})`,
							}}
						>

							<Box className={styles.tempZone}>
								<CanvasGridTemp
									data={tempGrid}
									width={changeW}
									height={changeH}
									maxTemp={maxTemp}
									minTemp={minTemp}
									isTempMax={false}
									handleCur={(temp) => console.log(temp)}
								/>

								<canvas
									ref={canvasRef}
									className={styles.areaCanvas}
									width={changeW}
									height={changeH}
								/>
							</Box>
						</div>
						<div className={styles.parametersBox}>
							<div className={styles.title}>Выбор зоны</div>
							<div>
								<Autocomplete
									disablePortal
									options={zones}
									getOptionLabel={(option) => option.name }
									value={selectedZone}
									onChange={handleChange}
									renderInput={(params) => (
										<CustomTextField {...params} />
									)}
									isOptionEqualToValue={(option, value) => option.id === value.id}
								/>
							</div>
							{componentPeriod.map(({title, value}: { title: string, value: number | string | undefined }) => (
								<div
									key={`Period-IR-${title}-${value}`}
									className={styles.title}
								>
									{title}: {value}
								</div>
							))}

							<div className={styles.title}>Параметры:</div>
							{contentParameters[type]
								.map(({title, value}:{title: string, value: number | string | undefined}) => (
									<div
										key={`Analitica-IR-${title}-${value}`}
										className={styles.title}
									>
										{title}: {value}
									</div>
								))}
						</div>
						{type === 'ir' &&
							(
								<div className={styles.containerButtonsEvent}>
									<div
										className={`${styles.buttonEvent} ${selectedZone?.idEventMax === idEvent && styles.active}`}
										onClick={()=>setIDEvent(selectedZone?.idEventMax)}
									>
										Максимум температуры
									</div>
									<div
										className={`${styles.buttonEvent} ${selectedZone?.idEventMin === idEvent && styles.active}`}
										onClick={()=>setIDEvent(selectedZone?.idEventMin)}
									>
										Минимум температуры
									</div>
								</div>
							)
						}
					</div>
				</CheckEmpty>
				<div className={styles.center}>
					<button
						className={
							(pageCounter === 0)
								? styles.arrowsIconBtnDisabled
								: styles.arrowsIconBtn
						}
						disabled={pageCounter === 0}
						onClick={() => handleChangePage(-1)}
					>
						<BackIcon title={'Назад'}/>
					</button>

					<div className={styles.textPage}>
						{dates}
					</div>
					<button
						className={
							((pageCounter === countRows - 1) || (countRows === 0))
								? `${styles.arrowsIconBtnDisabled} ${styles.rightArrow}`
								: `${styles.arrowsIconBtn} ${styles.rightArrow}`
						}
						disabled={(pageCounter === countRows - 1) || (countRows === 0)}
						onClick={() => handleChangePage(1)}
					>
						<BackIcon title={'Дальше'}/>
					</button>
				</div>
			</div>
		</ThemeProvider>
	);
});

export default IRAnaliticaCard;


